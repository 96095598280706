/* eslint-disable no-unused-vars */
<template>
<div class="container mt-5">
    <div class="row">
        <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div class="login-brand">
                <img :src="'logo.png'" alt="logo" width="150" class="shadow-light rounded-circle">
            </div>
            <!-- <router-view></router-view> -->
            <div class="card card-primary">
                <div class="card-header">
                    <h4>Login</h4>
                </div>

                <div class="card-body">
                    <form class="needs-validation" novalidate="" @submit.prevent="submitLogin()">
                        <div class="form-group">
                            <label for="username">Username</label>
                            <input id="username" type="text" class="form-control" name="username" tabindex="1" v-model='auth.username' required autofocus>
                            <div class="invalid-feedback">
                                Harap masukan username
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="d-block">
                                <label for="password" class="control-label">Password</label>
                                <div class="float-right">
                                    <!-- <router-link to="/auth/forgot-password" class="text-small">Lupa Password?</router-link> -->
                                </div>
                            </div>
                            <input id="password" type="password" class="form-control" name="password" v-model='auth.password' tabindex="2" required>
                            <div class="invalid-feedback">
                                Harap masukan password
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" name="remember_me" v-model='auth.remember_me' class="custom-control-input" tabindex="3" id="remember_me">
                                <label class="custom-control-label" for="remember_me">Remember Me</label>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="submit" :disabled="button_loading==true" class="btn btn-primary btn-lg btn-block" tabindex="4">
                                Login
                            </button>
                        </div>
                    </form>

                </div>
            </div>
            <!-- <div class="mt-5 text-muted text-center">
                Belum punya akun ? <router-link to="/auth/register" class="waves-effect">Register</router-link>
            </div> -->
            <!-- <div class="simple-footer">
                            Copyright &copy; Stisla 2018
                        </div> -->
        </div>
    </div>
</div>
</template>

<script>
import Swal from 'sweetalert2';
// import iziToast from 'izitoast';
import axios from 'axios';
import store from '../../store'
export default {
    name: 'Login',
    created() {
        document.title = "Login - Rudaya ~ Connect The Art";
    },
    mounted() {
        if (this.$store.state.isLoggedIn) {
            var redirect = '/';

            // if (this.$store.state.auth.level == "Admin"){
            //     redirect = '/admin/dashboard';
            // }
            this.$router.push({
                path: redirect
            });
            // window.location = redirect;
        }
    },
    data() {
        return {
            auth: {
                username: '',
                password: '',
                has_error: false,
                success: false,
                remember_me: false
            },
            button_loading: false,
            loader: '',
            loginError: false
        }
    },
    methods: {
        Loader() {
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                loader: 'dots',
                color: '#6777ef'

            });
        },
        getUser(token) {
            let uri = this.url + `api/v1/auth/user`;
            this.axios.get(uri, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(response => {

            });
        },
        submitLogin() {
            console.log("Login");
            this.Loader();
            this.button_loading = true;
            this.loginError = false;
            axios.post(this.url + 'api/v1/auth/login', {
                username: this.auth.username,
                password: this.auth.password,
                remember_me: this.auth.remember_me
            }).then(response => {
                this.loader.hide();
                this.button_loading = false;
                if (response.data.user.level == "Admin") {
                    localStorage.setItem('token', response.data.access_token)
                    localStorage.setItem('token_expire', response.data.expires_at)
                    // login user, store the token and redirect to dashboard
                    store.commit('loginUser')
                    this.getUser(response.data.access_token);
                    // store.commit('dataUser', response.data.user)
                    console.log(response.data.user)
                    localStorage.setItem('dataUser', JSON.stringify(response.data.user))
                    store.commit('dataUser', response.data.user)
                    Swal.fire({
                        title: 'Login Berhasil!',
                        text: 'Anda akan di arahkan dalam 3 detik',
                        icon: 'success',
                        timer: 3000,
                        showCancelButton: false,
                        showConfirmButton: false
                    });
                    setTimeout(() => {
                        this.$router.push({
                            path: '/dashboard'
                        });
                    }, 1000);

                } else {
                    Swal.fire({
                        title: 'Login Gagal!',
                        text: 'Username atau password salah!',
                        icon: 'error',
                    });
                }

            }).catch(error => {
                console.log(error);
                this.loginError = true;
                this.loader.hide();
                this.button_loading = false;
                Swal.fire({
                    title: 'Login Gagal!',
                    text: 'Username atau password salah!',
                    icon: 'error',
                });
            });
        },
        loginFunc() {
            console.log("Login");
            this.Loader();
            this.button_loading = true;
            axios
                .post(this.url + "api/v1/auth/login", {
                    username: this.auth.username,
                    password: this.auth.password
                })
                .then(res => {
                    this.loader.hide();
                    this.button_loading = false;
                    // save token to vuex
                    console.log(res.data.access_token);
                    this.$store.dispatch("saveToken", {
                        token: res.data.access_token
                    });
                    // get user data, store in vuex
                    this.$store.dispatch("fetchauth");
                    // redirect

                    Swal.fire({

                        title: 'Login Berhasil!',
                        text: 'Anda akan di arahkan dalam 3 Detik',
                        icon: 'success',
                        timer: 3000,
                        showCancelButton: false,
                        showConfirmButton: false
                    });
                    setTimeout(() => {
                        // const redirectTo = this.$auth.user().level === 'Admin' ? '/admin' : '/transaksi';
                        this.$router.push({
                            name: 'index'
                        });
                        window.location = '/';
                    }, 1000);

                })
                .catch(e => {
                    console.log(e);
                    this.loader.hide();
                    this.button_loading = false;
                    Swal.fire({

                        title: 'Login Gagal!',
                        text: 'Username atau password salah!',
                        icon: 'error',
                    });
                });
        },

    },
}
</script>
